import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header';

interface Chatbot {
  id: string;
  name: string;
  description: string;
  shareUrl: string;
}

const ChatbotsPage: React.FC = () => {
  const [chatbots, setChatbots] = useState<Chatbot[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // TODO: Replace with actual API call
    const fetchChatbots = async () => {
      // Temporary mock data
      setChatbots([{
        id: '12581162025',
        name: 'hotlines.ai support',
        description: 'ask any questions about hotlines.ai',
        shareUrl: `/chatbot/12581162025`
      }]);
      setIsLoading(false);
    };

    fetchChatbots();
  }, []);

  const copyShareLink = (shareUrl: string) => {
    const fullUrl = `${window.location.origin}${shareUrl}`;
    navigator.clipboard.writeText(fullUrl);
    // TODO: Add toast notification
  };

  const getEmbedCode = (botId: string) => {
    return `<script src="${window.location.origin}/embed.js"></script>`;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      
      <div className="max-w-7xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">Your Chatbots</h1>
        
        <div className="grid gap-6">
          {chatbots.map((chatbot) => (
            <div key={chatbot.id} className="bg-white p-6 rounded-lg shadow">
              <h2 className="text-xl font-semibold">{chatbot.name}</h2>
              <p className="text-gray-600 mt-2">{chatbot.description}</p>
              <div className="mt-4 flex gap-4">
                <button
                  onClick={() => copyShareLink(chatbot.shareUrl)}
                  className="text-blue-600 hover:text-blue-800"
                >
                  Copy Share Link
                </button>
                <Link
                  to={chatbot.shareUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-800"
                >
                  Preview Chat
                </Link>
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(getEmbedCode(chatbot.id));
                    // TODO: Add toast notification
                  }}
                  className="text-blue-600 hover:text-blue-800"
                >
                  Copy Embed Code
                </button>
              </div>
              <div className="mt-4 p-3 bg-gray-50 rounded text-sm font-mono">
                {getEmbedCode(chatbot.id)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChatbotsPage;