import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

interface Agent {
  id: number;
  name: string;
  external_agent_id: string | null;
  variables: string[];
  provider: 'RETELL' | 'HOTLINES';
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000/api';

interface NewCallFormData {
  provider: 'EXTERNAL' | 'HOTLINES';
  to_number: string;
  from_number: string;
  agent_id: string;
  variables: Record<string, string>;
  variableOrder: string[];
  batch_id?: string;
  contact_id?: string;
}

const UI_TO_BACKEND_PROVIDER = {
  'EXTERNAL': 'RETELL',
  'HOTLINES': 'HOTLINES'
} as const;

const NewCall: React.FC = () => {
  const navigate = useNavigate();
  const { session } = useAuth();
  const [formData, setFormData] = useState<NewCallFormData>({
    provider: 'EXTERNAL',
    to_number: '',
    from_number: '+14159361302',
    agent_id: 'agent_9f831015606e6a0c402a9e8b7e',
    variables: {
      contact_name: '',
      rm_name: '',
      rm_contact_num: ''
    },
    variableOrder: ['contact_name', 'rm_name', 'rm_contact_num'],
    batch_id: '',
    contact_id: '',
  });

  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [loadingAgents, setLoadingAgents] = useState(false);

  const fetchAgents = useCallback(async (provider?: 'EXTERNAL' | 'HOTLINES') => {
    if (!session) return;
    
    try {
      setLoadingAgents(true);
      const url = new URL(`${API_BASE_URL}/v1/dialer/agents`);
      if (provider) {
        url.searchParams.append('provider', UI_TO_BACKEND_PROVIDER[provider]);
      }

      const response = await fetch(url.toString(), {
        headers: {
          'Authorization': `Bearer ${session.access_token}`,
        },
      });
      
      if (!response.ok) throw new Error('Failed to fetch agents');
      const data = await response.json();
      setAgents(data.agents);
    } catch (err) {
      console.error('Error fetching agents:', err);
    } finally {
      setLoadingAgents(false);
    }
  }, [session]);

  useEffect(() => {
    fetchAgents(formData.provider);
  }, [formData.provider, session, fetchAgents]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    
    if (name === 'agent_id') {
      const selectedAgent = agents.find(agent => agent.id.toString() === value);
      if (selectedAgent) {
        const agentVariables = selectedAgent.variables as string[];
        const initialVariables = agentVariables.reduce((acc, key) => ({
          ...acc,
          [key]: ''
        }), {});

        setFormData(prev => ({
          ...prev,
          agent_id: selectedAgent.external_agent_id || '',
          variables: initialVariables,
          variableOrder: agentVariables,
        }));
      }
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleVariableChange = (oldKey: string, newKey: string, value: string, isKeyChange: boolean) => {
    setFormData(prev => {
      const updatedVariables = { ...prev.variables };
      const updatedOrder = [...prev.variableOrder];
      
      if (isKeyChange) {
        // Handling key change
        if (oldKey !== newKey) {
          const oldValue = updatedVariables[oldKey];
          delete updatedVariables[oldKey];
          updatedVariables[newKey] = oldValue;
          const keyIndex = updatedOrder.indexOf(oldKey);
          if (keyIndex !== -1) {
            updatedOrder[keyIndex] = newKey;
          }
        }
      } else {
        // Handling value change
        updatedVariables[oldKey] = value;
      }

      return {
        ...prev,
        variables: updatedVariables,
        variableOrder: updatedOrder,
      };
    });
  };

  const addVariableField = () => {
    const newKey = `key_${Object.keys(formData.variables).length}`;
    setFormData(prev => ({
      ...prev,
      variables: {
        ...prev.variables,
        [newKey]: ''
      },
      variableOrder: [...prev.variableOrder, newKey],
    }));
  };

  const removeVariableField = (key: string) => {
    setFormData(prev => {
      const updatedVariables = { ...prev.variables };
      delete updatedVariables[key];
      return {
        ...prev,
        variables: updatedVariables,
        variableOrder: prev.variableOrder.filter(k => k !== key),
      };
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrors([]);
    setLoading(true);

    if (!session) {
      setErrors(['Not authenticated']);
      setLoading(false);
      return;
    }

    const validationErrors: string[] = [];
    if (!formData.to_number) validationErrors.push('To Number is required.');
    if (!formData.from_number) validationErrors.push('From Number is required.');
    if (!formData.agent_id) validationErrors.push('Agent ID is required.');

    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      setLoading(false);
      return;
    }

    const payload = {
      provider: UI_TO_BACKEND_PROVIDER[formData.provider],
      to_number: formData.to_number,
      from_number: formData.from_number,
      agent_id: formData.agent_id,
      variables: formData.variables,
      batch_id: formData.batch_id || undefined,
      contact_id: formData.contact_id || undefined,
    };

    try {
      const response = await fetch(`${API_BASE_URL}/v1/dialer/calls`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to initiate call');
      }

      const data = await response.json();
      console.log('Call initiated:', data);
      navigate('/dialer/calls');
    } catch (err: any) {
      setErrors([err.message || 'Unknown error']);
    } finally {
      setLoading(false);
    }
  };

  const renderAgentSelect = () => (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">
        Agent
      </label>
      <select
        name="agent_id"
        value={agents.find(a => a.external_agent_id === formData.agent_id)?.id.toString() || ''}
        onChange={handleChange}
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white"
        required
        disabled={loadingAgents}
      >
        <option value="">Select an agent</option>
        {agents.map(agent => (
          <option key={agent.id} value={agent.id.toString()}>
            {agent.name}
          </option>
        ))}
      </select>
      {loadingAgents && (
        <p className="mt-1 text-sm text-gray-500">Loading agents...</p>
      )}
    </div>
  );

  return (
    <div className="min-h-full bg-gray-100 py-6 px-4 sm:px-6 lg:px-8">
      <div className="max-w-2xl mx-auto">
        <button
          onClick={() => navigate('/dialer/calls')}
          className="mb-4 text-blue-600 hover:text-blue-700 flex items-center gap-1 text-sm"
        >
          <span>←</span> Back to Calls
        </button>

        <h1 className="text-2xl font-semibold text-gray-900 mb-6">Start New Call</h1>

        {errors.length > 0 && (
          <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-md">
            <ul className="list-disc list-inside text-red-600">
              {errors.map((error, idx) => (
                <li key={idx}>{error}</li>
              ))}
            </ul>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6 bg-white shadow rounded-lg p-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Provider
            </label>
            <select
              name="provider"
              value={formData.provider}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white"
              required
            >
              <option value="EXTERNAL">EXTERNAL</option>
              <option value="HOTLINES" disabled>HOTLINES (Coming Soon)</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              To Number
            </label>
            <input
              type="tel"
              name="to_number"
              value={formData.to_number}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              placeholder="+1234567890"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              From Number
            </label>
            <input
              type="tel"
              name="from_number"
              value={formData.from_number}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              placeholder="+14159361302"
            />
          </div>

          {renderAgentSelect()}

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Variables
            </label>
            <div className="space-y-3">
              {formData.variableOrder.map((key, index) => (
                <div key={`variable_${index}`} className="flex items-center gap-2">
                  <input
                    type="text"
                    value={key}
                    onChange={(e) => handleVariableChange(key, e.target.value, formData.variables[key], true)}
                    className="w-1/3 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Key"
                  />
                  <input
                    type="text"
                    value={formData.variables[key]}
                    onChange={(e) => handleVariableChange(key, key, e.target.value, false)}
                    className="w-2/3 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Value"
                  />
                  <button
                    type="button"
                    onClick={() => removeVariableField(key)}
                    className="text-red-500 hover:text-red-700 p-2"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
            <button
              type="button"
              onClick={addVariableField}
              className="mt-3 text-sm text-blue-600 hover:text-blue-700 flex items-center gap-1"
            >
              <span>+</span> Add Variable
            </button>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Batch ID (Optional)
            </label>
            <input
              type="text"
              name="batch_id"
              value={formData.batch_id}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="batch_1234567890"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Contact ID (Optional)
            </label>
            <input
              type="text"
              name="contact_id"
              value={formData.contact_id}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="contact_1234567890"
            />
          </div>

          <div className="flex justify-end gap-3 pt-4">
            <button
              type="button"
              onClick={() => navigate('/dialer/calls')}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className={`px-4 py-2 text-sm font-medium text-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                loading 
                  ? 'bg-blue-400 cursor-not-allowed' 
                  : 'bg-blue-600 hover:bg-blue-700'
              }`}
            >
              {loading ? 'Creating...' : 'Create Call'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewCall; 