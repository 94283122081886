import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import FeatureCard from './FeatureCard';

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  const features = [
    {
      title: "Dialer",
      description: "Make AI-powered outbound calls for surveys, lead qualification, and follow-ups.",
      path: "/dialer"
    },
    {
      title: "Reception",
      description: "Set up your business phone system with IVR, call routing, and AI assistance.",
      path: "/reception"
    },
    {
      title: "Chatbot",
      description: "Deploy AI chatbots across your website, WhatsApp, and other channels.",
      path: "/chatbot"
    },
    {
      title: "CRM",
      description: "Track and manage your customer relationships in one place.",
      path: "/crm"
    },
    {
      title: "Profile",
      description: "Manage your business details and hotlines settings.",
      path: "/profile"
    }
  ];

  const handleSignIn = () => {
    // Handle sign in logic
    console.log('Sign in clicked');
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Header onSignIn={handleSignIn} />
      
      <main>
        <div className="max-w-7xl mx-auto px-4 py-16">
          <h1 className="text-4xl font-bold text-center mb-8">
            Your business suite for effortless communication.
          </h1>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
            {features.map((feature, index) => (
              <FeatureCard
                key={index}
                title={feature.title}
                description={feature.description}
                onClick={() => navigate(feature.path)}
              />
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default HomePage; 