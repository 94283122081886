import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import LandingPage from './components/LandingPage';
import HomePage from './components/HomePage';
import LoginPage from './components/LoginPage';
import DialerPage from './components/dialer/DialerPage';
import ReceptionPage from './components/reception/ReceptionPage';
import PhoneConfig from './components/reception/PhoneConfig';
import ChatbotsPage from './components/chatbots/ChatbotsPage';
import ChatInterface from './components/chatbots/ChatInterface';

// Add a loading component
const LoadingComponent = () => <div>Checking authentication...</div>;

// Create AppContent component to use the auth context
const AppContent = () => {
  const { session, loading } = useAuth();

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <div className="App">
      <Routes>
        <Route 
          path="/" 
          element={session ? <HomePage /> : <LandingPage />} 
        />
        <Route 
          path="/login" 
          element={!session ? <LoginPage /> : <Navigate to="/" />} 
        />
        <Route
          path="/dialer/*"  // The asterisk is important for nested routes
          element={session ? <DialerPage /> : <Navigate to="/login" />}
        />
        <Route
          path="/reception"
          element={session ? <ReceptionPage /> : <Navigate to="/login" />}
        />
        <Route
          path="/reception/config/:phoneNumber"
          element={session ? <PhoneConfig /> : <Navigate to="/login" />}
        />
        <Route
          path="/chatbot"
          element={session ? <ChatbotsPage /> : <Navigate to="/login" />}
        />
        <Route
          path="/chatbot/:botId"
          element={<ChatInterface />}  // No session check as this is public
        />
      </Routes>
    </div>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;
