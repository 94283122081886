import React from 'react';
import { Routes, Route, NavLink, Navigate } from 'react-router-dom';
import Header from '../Header';
import BatchesList from './BatchesList';
import ContactsList from './ContactsList';
import NewBatch from './NewBatch';
import CallsList from './CallsList';
import CallDetail from './CallDetail';
import NewCall from './NewCall';

const DialerPage: React.FC = () => {

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <Header />
      
      <div className="flex flex-1">
        {/* Left Sidebar */}
        <div className="w-64 bg-white shadow-sm">
          <nav className="mt-8">
            <NavLink
              to="calls"
              className={({ isActive }) =>
                `block px-4 py-2 text-sm ${
                  isActive ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-50'
                }`
              }
            >
              Calls
            </NavLink>
            <NavLink
              to="batches"
              className={({ isActive }) =>
                `block px-4 py-2 text-sm ${
                  isActive ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-50'
                }`
              }
            >
              Batches
            </NavLink>
            <NavLink
              to="contacts"
              className={({ isActive }) =>
                `block px-4 py-2 text-sm ${
                  isActive ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-50'
                }`
              }
            >
              Contacts
            </NavLink>
            <NavLink
              to="agents"
              className={({ isActive }) =>
                `block px-4 py-2 text-sm ${
                  isActive ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-50'
                }`
              }
            >
              Agents
            </NavLink>
          </nav>
        </div>

        {/* Main Content */}
        <div className="flex-1 overflow-auto">
          <Routes>
            <Route path="/" element={<Navigate to="calls" replace />} />
            <Route path="calls" element={<CallsList />} />
            <Route path="batches" element={<BatchesList />} />
            <Route path="contacts" element={<ContactsList />} />
            <Route path="calls/:id" element={<CallDetail />} />
            <Route path="new-call" element={<NewCall />} />
            <Route path="new-batch" element={<NewBatch />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default DialerPage; 