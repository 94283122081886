import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { CSVLink } from 'react-csv';

interface Call {
    id: string;
    to_number: string;
    from_number: string;
    created_at: string;
    call_status: string;
    duration: number;
    transcript: string | null;
    recording_url: string | null;
    disconnection_reason?: string;
}

interface CallDetails extends Call {
    from_number: string;
    disconnection_reason?: string;
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000/api';

const CallsList: React.FC = () => {
    const [calls, setCalls] = useState<Call[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [batches, setBatches] = useState<{ id: number; batch_name: string }[]>([]);
    const [batchesLoading, setBatchesLoading] = useState<boolean>(false);
    const [batchesError, setBatchesError] = useState<string | null>(null);

    const navigate = useNavigate();
    const { session } = useAuth();
    const [searchParams] = useSearchParams();

    // Fetch Batches
    useEffect(() => {
        const fetchBatches = async () => {
            setBatchesLoading(true);
            setBatchesError(null);
            try {
                const response = await fetch(`${API_BASE_URL}/v1/dialer/batches`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${session?.access_token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch batches');
                }

                const data = await response.json();
                setBatches(data.batches);
            } catch (err: any) {
                setBatchesError(err.message || 'Failed to fetch batches');
            } finally {
                setBatchesLoading(false);
            }
        };

        if (session?.access_token) {
            fetchBatches();
        }
    }, [session]);

    // Fetch Calls based on 'batch' query parameter
    useEffect(() => {
        const fetchCalls = async () => {
            setLoading(true);
            setError(null);

            try {
                const batchFromUrl = searchParams.get('batch');
                
                const queryParams = new URLSearchParams();
                if (batchFromUrl) {
                    queryParams.append('batch_id', batchFromUrl);
                }

                const url = `${API_BASE_URL}/v1/dialer/calls${queryParams.toString() ? `?${queryParams}` : ''}`;

                const response = await fetch(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${session?.access_token}`,
                    },
                });

                if (!response.ok) {
                    const data = await response.json();
                    throw new Error(data.error || 'Failed to fetch calls');
                }

                const data = await response.json();
                setCalls(data.calls.sort((a: Call, b: Call) => {
                    const dateA = new Date(a.created_at).setHours(0, 0, 0, 0);
                    const dateB = new Date(b.created_at).setHours(0, 0, 0, 0);
                    const dateComparison = dateB - dateA;
                    
                    if (dateComparison === 0) {
                        return (b.duration || 0) - (a.duration || 0);
                    }
                    
                    return dateComparison;
                }));
            } catch (err: any) {
                setError(err.message || 'Failed to fetch calls');
                setCalls([]);
            } finally {
                setLoading(false);
            }
        };

        if (session?.access_token) {
            fetchCalls();
        }
    }, [session, searchParams]);

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Invalid Date';
      
        return date.toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });
    };

    const formatDuration = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    // Modify the helper function to just clean the content without escaping
    const cleanField = (field: string | null | undefined): string => {
        if (field === null || field === undefined) return '';
        // Remove any line breaks and replace with space
        return field.replace(/[\r\n]+/g, ' ').trim();
    };

    const prepareCSVData = (calls: CallDetails[]) => {
        return calls.map(call => ({
            'To Number': call.to_number,
            'From Number': call.from_number,
            'Call Date': formatDate(call.created_at),
            'Status': call.call_status,
            'Duration': formatDuration(call.duration),
            'Disconnection Reason': call.disconnection_reason || '',
            'Transcript': cleanField(call.transcript)
        }));
    };

    return (
        <div className="p-6">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-semibold">Calls</h1>
                <div className="flex gap-4">
                    {calls.length > 0 && (
                        <CSVLink
                            data={prepareCSVData(calls)}
                            filename={`calls-export${searchParams.get('batch') ? `-batch-${searchParams.get('batch')}` : ''}-${new Date().toISOString().split('T')[0]}.csv`}
                            className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
                            enclosingCharacter={'"'}
                            separator=","
                        >
                            Export to CSV
                        </CSVLink>
                    )}
                    <button
                        onClick={() => navigate('/dialer/new-call')}
                        className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700"
                    >
                        Start New Call
                    </button>
                </div>
            </div>

            {/* Filters Section */}
            <div className="mb-6 flex flex-wrap gap-4 items-end">
                {/* Batch Filter */}
                <div className="w-64">
                    <label htmlFor="batchFilter" className="block text-sm font-medium text-gray-700 mb-2">
                        Batch
                    </label>
                    {batchesLoading ? (
                        <p>Loading batches...</p>
                    ) : batchesError ? (
                        <p className="text-red-500">{batchesError}</p>
                    ) : (
                        <select
                            id="batchFilter"
                            value={searchParams.get('batch') || ''}
                            onChange={(e) => {
                                const selectedBatch = e.target.value;
                                const params = new URLSearchParams(searchParams.toString());
                                if (selectedBatch) {
                                    params.set('batch', selectedBatch);
                                } else {
                                    params.delete('batch');
                                }
                                navigate(`/dialer/calls?${params.toString()}`);
                            }}
                            className="block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                        >
                            <option value="">All Batches</option>
                            {batches.map((batch) => (
                                <option key={batch.id} value={batch.id}>
                                    {batch.batch_name}
                                </option>
                            ))}
                        </select>
                    )}
                </div>

                {/* Future filters can be added here */}
            </div>

            {loading ? (
                <div className="p-6">Loading calls...</div>
            ) : error ? (
                <div className="p-6 text-red-500">Error: {error}</div>
            ) : calls.length === 0 ? (
                <div className="text-gray-600">No calls found.</div>
            ) : (
                <div className="bg-white rounded-lg shadow overflow-x-auto">
                    <table className="min-w-full">
                        <thead>
                            <tr className="border-b">
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">To Number</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Call Date</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Duration</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {calls.map(call => (
                                <tr key={call.id} className="border-b hover:bg-gray-50">
                                    <td className="px-6 py-4">{call.to_number}</td>
                                    <td className="px-6 py-4">{formatDate(call.created_at)}</td>
                                    <td className="px-6 py-4 capitalize">{call.call_status}</td>
                                    <td className="px-6 py-4">{formatDuration(call.duration)}</td>
                                    <td className="px-6 py-4">
                                        <button
                                            onClick={() => navigate(`/dialer/calls/${call.id}`)}
                                            className="text-blue-600 hover:underline text-sm"
                                        >
                                            View Details
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default CallsList; 