import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000/api';

interface CallDetailData {
  id: string;
  to_number: string;
  from_number: string;
  created_at: string;
  call_status: string;
  duration: number;
  transcript: string | null;
  recording_url: string | null;
  disconnection_reason: string | null;
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return 'Invalid Date';
  
  return date.toLocaleString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });
};

const formatDuration = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const CallDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [call, setCall] = useState<CallDetailData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { session } = useAuth();

  useEffect(() => {
    const fetchCallDetail = async () => {
      if (!session) {
        setError('Not authenticated');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`${API_BASE_URL}/v1/dialer/calls/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session.access_token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch call details');
        }

        const data = await response.json();
        setCall(data);
      } catch (err: any) {
        setError(err.message || 'Unknown error');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchCallDetail();
    }
  }, [id, session]);

  const openWhatsApp = (phoneNumber: string) => {
    // Remove any non-numeric characters from the phone number
    const cleanNumber = phoneNumber.replace(/\D/g, '');
    const messageText = 'Hi, I from the hotlines.ai team. You mentioned that monitoring agents is a challenge for you. Can I call to learn about how you manage this today?';
    // Create the WhatsApp URL with the cleaned number and encoded message
    const whatsappUrl = `https://wa.me/${cleanNumber}?text=${encodeURIComponent(messageText)}`;
    window.open(whatsappUrl, '_blank');
  };

  if (loading) {
    return <div className="p-6">Loading call details...</div>;
  }

  if (error) {
    return <div className="p-6 text-red-500">Error: {error}</div>;
  }

  if (!call) {
    return <div className="p-6 text-gray-600">Call not found.</div>;
  }

  return (
    <div className="min-h-full bg-gray-100 py-6 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <button
          onClick={() => navigate('/dialer/calls')}
          className="mb-4 text-blue-600 hover:text-blue-700 flex items-center gap-1 text-sm"
        >
          <span>←</span> Back to Calls
        </button>

        <h1 className="text-2xl font-semibold mb-6">Call Details</h1>

        <div className="bg-white shadow rounded-lg p-6 space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <span className="text-sm font-medium text-gray-500">To Number</span>
              <div className="mt-1 flex items-center gap-2">
                <p>{call.to_number}</p>
                <button
                  onClick={() => openWhatsApp(call.to_number)}
                  className="inline-flex items-center px-3 py-1 text-sm font-medium text-white bg-green-600 hover:bg-green-700 rounded-md"
                >
                  <span>WhatsApp</span>
                </button>
              </div>
            </div>
            
            <div>
              <span className="text-sm font-medium text-gray-500">From Number</span>
              <p className="mt-1">{call.from_number}</p>
            </div>

            <div>
              <span className="text-sm font-medium text-gray-500">Call Date</span>
              <p className="mt-1">{formatDate(call.created_at)}</p>
            </div>

            <div>
              <span className="text-sm font-medium text-gray-500">Status</span>
              <p className="mt-1 capitalize">{call.call_status}</p>
            </div>

            <div>
              <span className="text-sm font-medium text-gray-500">Duration</span>
              <p className="mt-1">{formatDuration(call.duration)}</p>
            </div>

            {call.disconnection_reason && (
              <div>
                <span className="text-sm font-medium text-gray-500">Disconnection Reason</span>
                <p className="mt-1 text-gray-900">{call.disconnection_reason}</p>
              </div>
            )}
          </div>

          {call.transcript && (
            <div className="pt-4 border-t">
              <span className="text-sm font-medium text-gray-500">Transcript</span>
              <p className="mt-2 whitespace-pre-wrap text-gray-700">{call.transcript}</p>
            </div>
          )}

          {call.recording_url && (
            <div className="pt-4 border-t">
              <span className="text-sm font-medium text-gray-500">Recording</span>
              <div className="mt-2">
                <audio 
                  controls 
                  src={call.recording_url}
                  className="w-full"
                >
                  Your browser does not support the audio element.
                </audio>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CallDetail; 