import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface ConfigFormData {
  officeHoursStart: string;
  officeHoursEnd: string;
  redirectionNumber: string;
  welcomeMessage: string;
  outOfOfficeMessage: string;
}

const PhoneConfig: React.FC = () => {
  const navigate = useNavigate();
  const { phoneNumber } = useParams();
  
  const [formData, setFormData] = useState<ConfigFormData>({
    officeHoursStart: '09:00',
    officeHoursEnd: '22:00',
    redirectionNumber: '+919551011996',
    welcomeMessage: 'Welcome to hotlines, our representative will be with you shortly.',
    outOfOfficeMessage: 'Our office is currently closed. Please call back during business hours from 9 AM to 10 PM.',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Saving configuration:', formData);
    navigate('/reception');
  };

  return (
    <div className="min-h-full bg-gray-100 py-6 px-4 sm:px-6 lg:px-8">
      <div className="max-w-2xl mx-auto">
        <button
          onClick={() => navigate('/reception')}
          className="mb-4 text-blue-600 hover:text-blue-700 flex items-center gap-1 text-sm"
        >
          <span>←</span> Back to Reception
        </button>

        <h1 className="text-2xl font-semibold text-gray-900 mb-6">
          Configure Phone Number: {phoneNumber}
        </h1>

        <form onSubmit={handleSubmit} className="space-y-6 bg-white shadow rounded-lg p-6">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Office Hours Start
              </label>
              <input
                type="time"
                name="officeHoursStart"
                value={formData.officeHoursStart}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Office Hours End
              </label>
              <input
                type="time"
                name="officeHoursEnd"
                value={formData.officeHoursEnd}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Redirection Number
            </label>
            <input
              type="tel"
              name="redirectionNumber"
              value={formData.redirectionNumber}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="+1234567890"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Welcome Message (Office Hours)
            </label>
            <textarea
              name="welcomeMessage"
              value={formData.welcomeMessage}
              onChange={handleChange}
              rows={3}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Out of Office Message
            </label>
            <textarea
              name="outOfOfficeMessage"
              value={formData.outOfOfficeMessage}
              onChange={handleChange}
              rows={3}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="flex justify-end gap-3 pt-4">
            <button
              type="button"
              onClick={() => navigate('/reception')}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Save Configuration
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PhoneConfig; 